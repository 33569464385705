<template>
  <div class="rounded-md bg-red-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          {{ translateError(props.error!) }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { XCircleIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: String,
});

function translateError(error: string): string {
  const retVal = {
    default: "Unable to sign in.",
    signin: "Try signing in with a different account.",
    oauthsignin: "Try signing in with a different account.",
    oauthcallbackerror: "Try signing in with a different account.",
    oauthcreateaccount: "Try signing in with a different account.",
    emailcreateaccount: "Try signing in with a different account.",
    callback: "Try signing in with a different account.",
    oauthaccountnotlinked:
      "To confirm your identity, sign in with the same account you used originally.",
    emailsignin: "The e-mail could not be sent.",
    credentialssignin:
      "Sign in failed. Check the details you provided are correct.",
    sessionrequired: "Please sign in to access this page.",
  }[error.toLowerCase()];

  return retVal || "Unable to sign in.";
}
</script>
